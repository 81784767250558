import React from "react";
import Game from "./GameApp"
import "./../assets/sass/game.sass";



export default function GamePage() {

    return (
        <div>
            <Game />
        </div>
    );
}
